<template>
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <div
      class="text-900 text-4xl font-normal border-round-top-2xl w-full md:w-12 p-3 border-left-2 border-right-2 border-top-2 border-500 text-gray-700 p-3">
      {{ $t("comment.commentTicket") }} {{ idTicket }}
    </div>
    <div class="border-round-bottom-2xl border-2 border-500 w-full md:w-12 p-2 md:p-5">
      <ul class="list-none w-12 p-0">
        <li class="flex flex-wrap pb-4 align-content-start">
          <p class="w-3">{{ $t("comment.comment") }}</p>
          <div class="flex w-9 flex-column">
            <Editor v-model:content="comment" ref="editor" editorStyle="height: 320px" @text-change="removeImages($event)" >
              <template v-slot:toolbar>
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-strike"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-script" value="sub"></button>
                  <button class="ql-script" value="super"></button>
                </span>
                
              </template>
            </Editor>
            <Message v-if="hasImages" severity="warn">{{ $t('comment.errorEditorImages') }}</Message>
          </div>
        </li>
        <li class="flex flex-wrap pb-4 align-content-start" v-if="isAdminPhl">
          <p class="w-3">{{ $t("comment.preMadeAnswer") }}</p>
          <div class="flex w-3 flex-column">
            <Dropdown :options="choix" v-model="selectedResponse" :placeholder="$t('comment.selectResponse')" @change="changeResponse()"/>
          </div>
        </li>
        <li class="flex flex-wrap align-items-center">
          <p class="w-3">{{ $t("ticketHistory.attachment") }}</p>
          <FileUpload id="file" mode="basic"
            class="md:w-12rem p-button-outlined p-button-plain bg-indigo-500 text-0 border-round-2xl"
            :chooseLabel="$t('comment.add')" :auto="true" customUpload @uploader="customBase64Uploader">
            <template #uploadicon>        
              <i :class="icon"></i>   
            </template>  
          </FileUpload>
        </li>
        <li class="flex flex-wrap align-items-center" v-for="(pj, index) in pjs" :key="index">
          <p class="w-3"></p>
          <p class="flex gap-2 text-green-500 m-0 align-items-center">
            <i class="pi pi-check-circle text-green-500"></i> 
            {{ pj }} 
            <Button icon="pi pi-trash" class="text-red-500 bg-white border-0" @click="supprFile(pj)"></Button>
          </p>
        </li>
        <li>
          <div class="flex justify-content-center pt-6">
            <Button type="submit" id="click" :disabled="hasImages"
              class="flex md:w-12rem border-round-2xl justify-content-center align-items-center w-auto mt-3"
              @click="envoyer()">{{ $t("comment.validate") }}
            </Button>
            <Button
              @click="supprFiles()"
              class="flex md:w-12rem border-round-2xl justify-content-center align-items-center ml-3 border-2 border-cyan-500 surface-0 text-cyan-500 w-auto mt-3">
              {{ $t("comment.cancel") }}
            </Button>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <Toast />
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      idTicket: "",
      comment: "",
      errorComment: false,
      infosDolibarr: "",
      isAdminPhl: false,
      reponses: [],
      choix: [],
      selectedResponse: "",
      pjs: [],
      icon: 'pi pi-upload',
      hasImages: false
    };
  },
  mounted() {
    // Réponses pré générées pour les admins PHL
    this.isAdminPhl = localStorage.getItem('adminPhl') !== undefined && localStorage.getItem('adminPhl') === 'true';
    if(this.isAdminPhl){
      fetch('/reponses.json')
        .then(response => response.json())
        .then(jsonResponse => {
          if(jsonResponse[this.$i18n.locale] !== undefined){
            this.reponses = jsonResponse[this.$i18n.locale];
            this.choix = Object.keys(jsonResponse[this.$i18n.locale]);
          }
          else{
            this.reponses = jsonResponse['en'];
          }
        }
      );
    }
    //récupération du token backend dans le local storage
    this.token = localStorage.getItem("token");
    //on récupère les informations du tiers
    this.idClientPHL = localStorage.getItem("idTiers");
    //récupération des informaions dolibarr a mettre avant le commentaire
    this.infosDolibarr = localStorage.getItem("infosDolibarr");
    //récupération du bouton retour du navigateur pour supprimer les fichiers
    var self = this;
    window.onpopstate = function(event) {
      self.supprFiles();
    }
    //on vérifie qu'il a le droit de commenter 
    if(localStorage.getItem("commentaire") !== undefined && localStorage.getItem("commentaire") === "ok"){
      this.idTicket = this.$route.params.idTicket
    }
    else{
      this.$router.push("/404");
    }

  },
  methods: {
    removeImages(event){
      let html = event.htmlValue;
      if(html.includes('<img src=') && html.includes(';base64')){
        this.hasImages = true;
      }
      else{
        this.hasImages = false;
      }
    },
    customBase64Uploader(event){
      let click = document.getElementById('click');
      this.icon = 'pi pi-spin pi-spinner';
      const file = event.files[0];
      let extensionInterdite = file.name.lastIndexOf('.exe');
      if (extensionInterdite === -1){
        //envoie le fichier vers le back-end 
        axios.post(
          process.env.VUE_APP_URL_BACK_END + "/api/jira/moveFiles",
          {
            file: file,
            idTicket: this.$route.params.idTicket,
            idClientPhl: localStorage.getItem("idTiers")
          },
          {
            headers: {
              //on envoie le token d'authentification et le format des données envoyés en POST
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer "+localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.icon = 'pi pi-upload';
            this.$toast.add({
              severity: "success",
              summary: "Succès",
              detail: file.name,
              life: 3000,
            });
            click.focus();
            this.pjs.push(res['data'])
          }
        })
        .catch((e) => {
          //Token invalide ou expiré
          if(e['response']['status'] === 401){
              this.$router.push("/login");
          }
          //capture des erreurs revoyées par le back-end
          else{
            this.$toast.add({
              severity: "error",
              summary: this.$t('erreur.erreur'),
              detail: this.$t('erreur.erreurInterne'),
              life: 3000,
            });
            this.icon = 'pi pi-upload';
            click.focus();
          }
        });
      }
      else {
        this.$toast.add({
          severity: "error",
          summary: "Fichier non autorisé (.exe)",
          life: 3000,
        });
        this.icon = 'pi pi-upload';
        click.focus();
      }
    },
    envoyer() {
      //on vérifie que le commentaire n'est pas vide et acceptable (au minimum 3 caractères)
      // this.errorComment = this.comment.length < 3;
      // if (!this.errorComment) {
        document.getElementById('click').disabled = true;
        this.comment = document.getElementsByClassName("ql-editor")[0].innerHTML;
        //permet de poster un commentaire sur un ticket Jira
        axios
          .post(
            process.env.VUE_APP_URL_BACK_END + "/api/jira/addComment",
            {
              //on envoie les paramètres au back-end
              idTicket: this.idTicket,
              comment: this.comment,
              infosDolibarr: this.infosDolibarr,
              idClientPhl: this.idClientPHL
            },
            {
              headers: {
                //on envoie le token d'authentification et le format des données envoyés en POST
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer "+this.token,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              //si le statut de la requête est bon alors on indique à l'utilsateur la réussite de la requête
              this.$toast.add({
                severity: "success",
                summary: "Commentaire envoyé",
                life: 3000,
              }),
              //on enlève la variable permettant de savoir s'il a le droit de commenter sur ce ticket
              localStorage.removeItem("commentaire");
              // puis il est redirigé sur le ticket qu'il vient de
              this.$router.push("/ticket/" + this.idTicket);
            }
          }).catch((e) => {
            document.getElementById('click').disabled = false;
            //Token invalide ou expiré
            if(e['response']['status'] === 401){
                this.$router.push("/login");
            }
            //capture des erreurs revoyées par le back-end
            else{
              this.$toast.add({
                severity: "error",
                summary: this.$t('erreur.erreur'),
                detail: this.$t('erreur.erreurInterne'),
                life: 3000,
              });
            }
          });
      // }
    },
    //permet de supprimer le dossier contenant les pièces jointes que l'utilisateur à transmit avant d'annuler le ticket
    supprFiles() {
      axios.post(
        process.env.VUE_APP_URL_BACK_END + "/api/jira/supprFiles", 
        {
          //on envoie les paramètres au back-end
          client: this.idClientPHL,
          idTicket: this.idTicket
        },
        { 
          headers: { 
            //on envoie le token d'authentification et le format des données envoyés en POST
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer "+this.token
          }
        }
      ).then((res) => {
        if(res.status === 200){
          //si le statut de la requête est bon alors on renvoie l'utilisateur vers le ticket
          this.$router.push("/ticket/" + this.idTicket);
        }
      }).catch((e) => {
        //Token invalide ou expiré
        if(e['response']['status'] === 401){
            this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else{
          this.$toast.add({
            severity: "error",
            summary: this.$t('erreur.erreur'),
            detail: this.$t('erreur.erreurInterne'),
            life: 3000,
          });
        }
      })
    },
    // Changement de la valeur du commentaire
    changeResponse(){
      this.comment = this.reponses[this.selectedResponse];
      this.$refs.editor.quill.setText(this.comment, 'silent');
    },
    supprFile(pj){
      axios.post(process.env.VUE_APP_URL_BACK_END + "/api/jira/supprFile", 
        {
          //on envoie les paramètres au back-end
          idClient: this.idClientPHL,
          idTicket: this.idTicket,
          fileName: pj
        },
        { 
          headers: { 
            //on envoie le token d'authentification et le format des données envoyés en POST
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer "+this.token
          }
        }
      ).then((res) => {
        if(res.status === 200){
          //si le statut de la requête est bon alors on renvoie l'utilisateur vers le ticket
          this.pjs = this.pjs.filter((name) => pj !== name);
        }
      }).catch((e) => {
        console.log(e);
        //Token invalide ou expiré
        if(e['response']['status'] === 401){
            this.$router.push("/login");
        }
        //capture des erreurs revoyées par le back-end
        else{
          this.$toast.add({
            severity: "error",
            summary: this.$t('erreur.erreur'),
            detail: this.$t('erreur.erreurInterne'),
            life: 3000,
          });
        }
      })
    }
  },
};
</script>
